<template>
    <div class="invite-modal modal-bordered">
        <div class="invite-modal_content">
            <h1 class="modal-title" v-html="$content('friends_modal_title')"></h1>
            <p v-html="$content('friends_modal_text')"></p>
            <FormWrapper
                @submitted="$modal.notify({key: 'invite_send_success', type: 'success'})"
                name="invite"
                repository="referral"
                :methods="{preload: 'getInviteForm', submit: 'invite'}">
                    <template v-slot:invite_email="props">
                        <input
                            name="email"
                            :placeholder="$content('friends_modal_input')"
                            v-model="props.data.value"
                            class="common-input"
                            type="text" />
                    </template>
                    <template v-slot:submit>
                        <ProjectButton variant="app" radius="200">
                            <div class="w-100" v-html="$content('friends_modal_btn')"></div>
                        </ProjectButton>
                    </template>
            </FormWrapper>
        </div>
    </div>
</template>

<script>
import AuthInput from "@/components/Inputs/AuthInput";
import ProjectButton from "@/components/Buttons/ProjectButton";
import FormWrapper from "../Wrappers/FormWrapper";
import Submitter from "../Wrappers/Submitter";
export default {
name: "Invite",
    components: {Submitter, FormWrapper, ProjectButton, AuthInput}
}
</script>

<style lang="scss">
    @import "../../styles/mixins";

    .invite-modal {
        width: 440px;
        background: white;

        @include only-xs {
            width: 100%;
        }

        &_content {
            padding: 45px 0;
            width: 300px;
            margin: 0 auto;

            @include only-xs {
                width: 100%;
                padding: 30px 15px;
            }

            & h1 {
                font-size: 18px;
            }

            & p {
                color: var(--main-dark);
                margin: 38px 0;
                font-size: 14px;
                font-family: Montserrat-Regular, sans-serif;
                text-align: center;
            }

            & input {
                background: #efefef;
            }
        }
    }
</style>